<template>
  <div>
    <div style="padding: 20px">
      <h4>单图片文件上传</h4>
      <a-upload
        list-type="picture-card"
        :showUploadList="false"
        accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
        :beforeUpload="beforeUpload"
        :customRequest="customRequest"
      >
        <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">上传图片</div>
        </div>
      </a-upload>
    </div>
    <div style="padding: 20px">
      <h4>多图片文件上传</h4>
      <a-upload
        list-type="picture-card"
        accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
        :fileList="fileList"
        :beforeUpload="beforeUpload"
        :customRequest="customRequest2"
        @change="change"
      >
        <div v-if="fileList.length < 5">
          <a-icon :type="loading2 ? 'loading' : 'plus'" />
          <div class="ant-upload-text">上传图片</div>
        </div>
      </a-upload>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      imageUrl: "", // 单图的地址
      loading: false,
      file: {},
      fileList: [], // 多图的地址，存放在obj.url里面
      loading2: false,
    };
  },
  // 事件处理器
  methods: {
    change(e) {
      this.file = e.file;
      this.fileList = e.fileList;
    },
    // 上传前钩子
    beforeUpload(file) {
      // return this.upload.getToKen()
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },

    // 重置文件上传 - 单文件
    customRequest(e) {
      this.$upload({
        file: e.file,
        progress: (e) => {
          this.loading = true;
        },
        success: (e) => {
          this.loading = false;
          this.imageUrl = e;
        },
      });
    },

    // 重置文件上传 - 多文件
    customRequest2(e) {
      this.$upload({
        file: e.file,
        progress: (e) => {
          this.loading2 = true;
        },
        success: (e) => {
          this.fileList.forEach((item) => {
            if (item.uid == this.file.uid) {
              item.status = "success";
              item.url = e;
            }
          });
          this.loading2 = false;
        },
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
h4 {
  padding: 10px;
}
</style>
